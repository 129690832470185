import React from 'react';
import {useNavigate} from 'react-router-dom';
import UserSettings from '../UserSettings';
import {UserData} from '../types/types';

type UserSettingsWrapperProps = {
    isLoggedIn: boolean;
    onLoginSuccess: (storedData: string) => void;
    data: UserData | null;
}

const UserSettingsWrapper: React.FC<UserSettingsWrapperProps> = ({isLoggedIn, onLoginSuccess, data}) => {
	const navigate = useNavigate();
	return (
	<div>
	<UserSettings navigate={navigate} data={data} onLoginSuccess={onLoginSuccess}/>
	</div>
	);
};

export default UserSettingsWrapper;
