import React, { Component } from 'react';

interface TimerProps {
  initialTime: number; // Time in seconds
  onTimerEnd: () => void;
}

interface TimerState {
  timeLeft: number;
}

class Timer extends Component<TimerProps, TimerState> {
  intervalId: NodeJS.Timeout | null = null;

  constructor(props: TimerProps) {
    super(props);
    this.state = {
      timeLeft: this.props.initialTime,
    };
  }

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate(prevProps: TimerProps) {
    // Reset the timer if initialTime prop changes
    if (this.props.initialTime !== prevProps.initialTime) {
      this.resetTimer();
    }
  }

  // Add a resetTimer method
  resetTimer = () => {
    this.setState({ timeLeft: this.props.initialTime });
    this.clearTimer();
    this.startTimer();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  startTimer = () => {
    this.clearTimer(); // Clear existing timer
    this.intervalId = setInterval(this.tick, 1000);
  }

  clearTimer = () => {
    if (this.intervalId) clearInterval(this.intervalId);
  }

  tick = () => {
    this.setState(prevState => {
      if (prevState.timeLeft === 1) {
        this.props.onTimerEnd();
        return { timeLeft: 0 };
      } else {
        return { timeLeft: prevState.timeLeft - 1 };
      }
    });
  };

  formatTime = (seconds: number) => {
    if (seconds >= 60) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} minute${minutes > 1 ? 's' : ''} ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  };

  render() {
    const { timeLeft } = this.state;
    return <div>Timer: {this.formatTime(timeLeft)}</div>;
  }
}

export default Timer;

