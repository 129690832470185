// React component to upload an image with QuestionID as a prop
import React, { useState } from 'react';
import axios from 'axios';
import {API_URL} from '../config';

type ImageUploaderProps = {
  questionID: string;
};

const ImageUploader: React.FC<ImageUploaderProps> = ({ questionID }) => {
  const [image, setImage] = useState<File | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    if (image && questionID) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        axios.post(`${API_URL}/upload_image`, {
          questionID,
          image: base64String.split(',')[1]  // Remove the prefix from the base64 string
        });
      };
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} />
      <button onClick={handleSubmit}>Upload Image</button>
    </div>
  );
};

export default ImageUploader;
