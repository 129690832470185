import React, { useState } from 'react';
import axios, {AxiosError} from 'axios';
import {useNavigate} from 'react-router-dom';
import WarningMessage from './WarningMessage';
import '../../css/RegisterForm.css'
import { API_URL } from '../../config';

type FormData = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
  teacherCode?: string; // Optional field for teacher's code
};


const RegistrationForm: React.FC = () => {
  const [warningMessage, setWarningMessage] = useState('');
  const [warningKey, setWarningKey] = useState(0);
  const [formData, setFormData] = useState<FormData>({ username: '', firstName: '', lastName: '', email: '', password: '', teacherCode: '', role: 'STUDENT' });
  const navigate = useNavigate();
  
  const handleCancel = () => {
  	navigate('/');
  }

	interface ErrorResponse {
	  error: string;
	}
	
	const isTeacher = formData.role === 'TEACHER'; // Determine if the selected role is 'TEACHER'

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
	  e.preventDefault();
	  try {
	    const response = await axios.post(`${API_URL}/register`, formData);
	    console.log(response.data.message);
	    // Handle further logic (like redirection or displaying a success message)
	    navigate('/');
	  } catch (error) {
	    console.error('Registration failed', error);

	    let errorMessage = 'Registration failed. Please try again.';

	    if (axios.isAxiosError(error)) {
	      // Type assertion for the error response data
	      const serverError = error as AxiosError<ErrorResponse>;
	      if (serverError.response && serverError.response.data) {
		errorMessage = serverError.response.data.error || errorMessage;
	      }
	    }

	    setWarningMessage(errorMessage);
	    setWarningKey(prevKey => prevKey + 1);
	  }
	};

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
  <div className="registration-form-container">
  	{warningMessage && <WarningMessage message={warningMessage} key={warningKey} />}
	    <form onSubmit={handleSubmit} className="registration-form">
	      <input
		type="text"
		name="username"
		value={formData.username}
		onChange={handleChange}
		placeholder="Username"
		required
	      />
	      <input
	      	type="firstName"
	      	name="firstName"
	      	value={formData.firstName}
	      	onChange={handleChange}
	      	placeholder="First Name"
	      	required
	      />
	      <input
	      	type="lastName"
	      	name="lastName"
	      	value={formData.lastName}
	      	onChange={handleChange}
	      	placeholder="Last Name"
	      	required
	      />
	      <input
		type="email"
		name="email"
		value={formData.email}
		onChange={handleChange}
		placeholder="Email"
		required
	      />
	      <input
		type="password"
		name="password"
		value={formData.password}
		onChange={handleChange}
		placeholder="Password"
		required
	      />
	      {isTeacher && (
		  <input
		    type="password"
		    name="teacherCode"
		    value={formData.teacherCode}
		    onChange={handleChange}
		    placeholder="Teacher Code"
		    required={isTeacher}
		  />
		)}
	      <select name="role" value={formData.role} onChange={handleChange}>
		<option value="STUDENT">Student</option>
		<option value="TEACHER">Teacher</option>
		{/*<option value="ADMIN">Admin</option>*/}
	      </select>
	      <button type="submit" className="register-button">Register</button>
	    </form>
    </div>
  );
};

export default RegistrationForm;

