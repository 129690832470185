import React, { Component } from 'react';
import { IChoice } from './types/types';
import '../css/ExamQuestion.css'; // Import the CSS file for styles

// Define the props type for ExamQuestion
type ExamQuestionProps = {
  headerSection: string;
  index: number;
  passage: string | null;
  question: string;
  choices: IChoice[];
  selectedOption: number | null;
  singleChoiceInput: string;
  onOptionSelect: (option: number) => void; 
  onSingleChoiceInput: (input: string) => void;
  questionID: string; 
  imageQuestion: string | null;
  multiChoice: boolean;
  struckOptions: Set<number>;
  toggleStrikeThrough: (optionIndex: number) => void;
};

type ExamQuestionState = {
  selectedOption: number | null;
  showFeedback: boolean;
  singleChoiceInput: string;
  answers: {[questionID: string]: number | string | null}; // Object to hold answers  
};

class ExamQuestion extends Component<ExamQuestionProps, ExamQuestionState> {
  constructor(props: ExamQuestionProps) {
    super(props);

    this.state = {
      selectedOption: null,
      showFeedback: false,
      singleChoiceInput: '',
      answers: {}, // Object to hold answers
    };
  }
  //TODO: REMOVE FEEDBACK TO AVOID CHEATING
  handleOptionSelect = (option: number) => {
    // Call the onOptionSelect prop when an option is selected
    // Can set showFeedback to true to see feedback for debugging
     if (!this.props.struckOptions.has(option)) {
    	this.setState({ selectedOption: option });
    	this.props.onOptionSelect(option);
    }
  }

  handleSingleChoiceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({singleChoiceInput: event.target.value});
    this.props.onSingleChoiceInput(event.target.value)
  }

    // Function to remove the first sentence from a rationale string
    correctSentence(rationale: string): string {
      const bestAnswer = rationale.indexOf("is the best answer")
      const incorrectAnswer = rationale.indexOf("is incorrect")
      if (bestAnswer === -1) {
        // No period found, or it's the last character; return the whole string
        if(incorrectAnswer === -1){
          return rationale
        }
        return rationale.substring(incorrectAnswer);;
      }
      // Return the string after the first period + 1 to skip the space
      return rationale.substring(bestAnswer);
    }


  render() {
    const { index, passage, question, choices, questionID, imageQuestion, selectedOption, singleChoiceInput, multiChoice } = this.props;
    const { showFeedback } = this.state;

    return (
      <div className="exam-content">
        {<div className="question-side">
          {imageQuestion && (
            <img src={`data:image/jpeg;base64,${imageQuestion}`} alt="Uploaded" className="image-question" />
          )}
	  {passage &&
          <h2 className="passage">
            <div dangerouslySetInnerHTML={{ __html: passage }} />
          </h2>}
          {showFeedback && selectedOption != null && (
            <div className="feedback">
              {choices[selectedOption][2] ? (
                <p>Choice {String.fromCharCode(65 + selectedOption)} is Correct. <div dangerouslySetInnerHTML={{ __html: choices[selectedOption][3] }} /></p>
              ) : (
                <p>Choice {String.fromCharCode(65 + selectedOption)} is Incorrect. <div dangerouslySetInnerHTML={{ __html: choices[selectedOption][3] }} /></p>
              )}
            </div>
          )}
        </div>}
        <div className="divider"></div>
        <div className={passage ? "options-side" : "option-side-response"}>
          <div className="choice-header">
            <div className="left-section">
              <div className="number-square">{index + 1}</div>
              {/*(<div className="feedback-id">{questionID}</div>*/}
            </div>
            <div className="center-section">
              <span>Mark for Review</span>
            </div>
            <div className="right-section">
              <button className="abc-button">ABC</button>
            </div>
          </div>
          <h3><div dangerouslySetInnerHTML={{ __html: question }}/></h3>
          {multiChoice ? (<div><h3 className="options-question">Choose an option:</h3>
            <ul className="options-list">
            {choices.map((option, index) => (
       
	         <div className="strike-div" key={index}>
		    <li
		      className={`option ${this.props.selectedOption === index ? 'selected' : ''} ${this.props.struckOptions.has(index) ? 'strike-through' : ''}`}
		      onClick={() => !this.props.struckOptions.has(index) && this.props.onOptionSelect(index)}
		    >
		      <span className="option-letter">{String.fromCharCode(65 + index)}</span>
		      <div dangerouslySetInnerHTML={{ __html: option[1] }} />
		    </li>
		    <button
		      className={`${this.props.struckOptions.has(index) ? 'strike-button-selected' : 'strike-button'}`}
		      onClick={(e) => {
			e.stopPropagation(); // Prevent the li click event
			this.props.toggleStrikeThrough(index);
			console.log(this.props.struckOptions);
		      }}
		    >
      		{this.props.struckOptions.has(index) ?  'Undo' :  `\u00A0\u00A0${String.fromCharCode(65 + index)}\u00A0\u00A0`}
		</button>

		</div>
            ))}
            </ul>
          </div>) : (
          <div className="single-choice-section"> 
            <input 
              type="text"
              value={singleChoiceInput}
              onChange={this.handleSingleChoiceInputChange}
              className="single-choice-input"
              />
            <div className="single-choice-preview">
              <h4>Preview:</h4>
              <p>{this.state.answers[questionID]}</p>
            </div>
          </div>
        )}
        </div>
      </div>
    );
  }
}

export default ExamQuestion;
