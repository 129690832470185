import React, { useState, useEffect } from 'react';
import '../../css/WarningMessage.css';

interface WarningMessageProps {
  message: string;
  key: any;
}

const WarningMessage: React.FC<WarningMessageProps> = ({ message, key }) => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setShowWarning(true);
    const timer = setTimeout(() => {
      setShowWarning(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [key]);

  if (!showWarning) {
    return null;
  }

  return (
    <div className="warning-message">
      {message}
    </div>
  );
};

export default WarningMessage;
