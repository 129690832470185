import { useLocation} from 'react-router-dom';
import Exam from '../Exam';

const ExamWrapper = () => {
  const location = useLocation();
  
  // Extract the state or other necessary data
  const {TestID, userData, isPractice } = location.state || {};

  return (
    <Exam
      TestID={TestID}
      userData={userData}
      isPractice={isPractice}
    />
  );
};

export default ExamWrapper;
