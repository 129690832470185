import React, { useState, useEffect } from 'react';
import QuestionFormWrapper from './components/wrapper/QuestionFormWrapper';
import logo from './logo.svg';
import './App.css';
import QuestionsFetcher from './components/QuestionFetcher';
import QuestionForm from './components/tools/QuestionForm';
import ImageUploader from './components/ImageUploader';
import FullLengthPracticeWrapper from './components/wrapper/FullLengthPracticeWrapper';
import ExamWrapper from './components/wrapper/ExamWrapper';
import ProtectedRoute from './components/tools/ProtectedRoute';
import RegistrationForm from './components/tools/RegistrationForm'
import ProfileWrapper from './components/wrapper/ProfileWrapper';
import UserSettingsWrapper from './components/wrapper/UserSettingsWrapper';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {UserData} from './components/types/types';
import { API_URL } from './config';

function App() {
	   // Safely initialize `data` from localStorage
    const storedUserData = localStorage.getItem('userData');
    const initialUserData = storedUserData ? JSON.parse(storedUserData) : null;
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [data, setData] = useState<UserData | null>(initialUserData);

    console.log(API_URL);

    useEffect(() => {
        const handleStorageChange = () => {
            const storedData = localStorage.getItem('userData');
            setData(storedData ? JSON.parse(storedData) : null);
        };

        // Subscribe to the custom event
        window.addEventListener('localStorageChange', handleStorageChange);

        // Check if user is logged in when component mounts
        const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
        setIsLoggedIn(loggedIn);
        
        // Cleanup function
        return () => {
            window.removeEventListener('localStorageChange', handleStorageChange);
        };
    }, []);

    const handleLoginSuccess = (storedData: string) => {
	setIsLoggedIn(true);
	localStorage.setItem('isLoggedIn', 'true');
	localStorage.setItem('userData', storedData);
	window.dispatchEvent(new Event('localStorageChange'));
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
	// Clear Login State
	localStorage.removeItem('isLoggedIn');
	localStorage.removeItem('userData');
	window.dispatchEvent(new Event('localStorageChange'));

    };


  return (
    <Router>
	<Routes>
	   <Route path="/" element={
	       <ProfileWrapper isLoggedIn={isLoggedIn} onLoginSuccess={handleLoginSuccess} data={data}/>
	       /*<FullLengthPracticeWrapper isLoggedIn={isLoggedIn}  onLoginSuccess={handleLoginSuccess} logout={handleLogout} data={data}/>*/
	       } />
	   <Route path="/user" element={
	       <ProtectedRoute isLoggedIn={isLoggedIn} >
	       <UserSettingsWrapper isLoggedIn={isLoggedIn}  onLoginSuccess={handleLoginSuccess} data={data}/>
	       </ProtectedRoute>
	       } />
	   <Route path="/exam" element={
	       <ProtectedRoute isLoggedIn={isLoggedIn} >
		<ExamWrapper />
	       </ProtectedRoute>
	       }/>
	   <Route path="/register" element={
	       <ProtectedRoute isLoggedIn={!isLoggedIn} >
		       <RegistrationForm />
		</ProtectedRoute>
		}/>
	    <Route path="/upload" element={
	    	<ProtectedRoute role={data?.role} isLoggedIn={localStorage.getItem('isLoggedIn') === 'true'} >
		    <QuestionFormWrapper data={data}/>
		</ProtectedRoute>
		}/>
	   <Route path="/approve" element={
	       <QuestionsFetcher />
	   } />
    {/*     <Exam headerSection={''} footerSection={''} />*(/}
    {/*<QuestionsFetcher /> */}
    {/* <QuestionForm />*/}
    {/*<ImageUploader />*/}

	</Routes>
    </Router>
  );
}

export default App;
