import React, { Component } from 'react';
import axios from 'axios';
import { IQuestion } from './types/types';
import "../css/Results.css"
import { API_URL } from '../config';

type ResultsProps = {
    addToDB: boolean;
    isPractice: boolean;
    cName: string;
    answers: {[questionID: string]: number | string | null};
    times: {[questionID: string]: number};
    questions: IQuestion[];
    TestID: string;
    UserID?: number;
}

interface ResultData {
    QuestionID: string;
    correctness: boolean;
    answer: string | null;
    time: number;
}

interface FilterState {
    section: number
    module: number;
    difficulty: string;
    domain: string;
    skill: string;
    test: string;
}

type ResultsState = {
    correctness: {
        [section: number] : {
	    [module: number] : Array<ResultData>;
	}
    };
    filters: {
                section: 0,
                module: 0,
                difficulty: '',
                domain: '',
                skill: '',
                test: ''
            };

    sectionScores: {
	[section: number] : {
	    score: number,
	    totalScore: number,
	    finalScore: number
	}
    };

    questionNumber: {
	[questionID: string]: number
    }
 
};

type Difficulty = 'Easy' | 'Medium' | 'Hard';

class Results extends Component<ResultsProps, ResultsState> {
  constructor(props: ResultsProps) {
	super(props);
	this.state = {
	    correctness: {},
	    sectionScores: {},
	    filters: {
                section: 0,
                module: 0,
                difficulty: '',
                domain: '',
                skill: '',
                test: ''
            },
	    questionNumber: {}
	};
  }
  requestSent = false;
  calculateResults() {
    const results: { [section: number]: { [module: number]: Array<ResultData> } } = {};
    const sectionScoresTemp : {[section: number] : {score: number, totalScore: number, finalScore: number}} = {};
    let score = 0;
    let totalScore = 0;
    this.props.questions.forEach((question) => {

	if (!results[question.Section]) {
	    results[question.Section] = {};
	    sectionScoresTemp[question.Section] = {score: 0, totalScore: 0, finalScore: 200};
	}
	if(!results[question.Section][question.Module]) {
	    results[question.Section][question.Module] = [];
	}

	const difficultyScoreMultiplier: Record<Difficulty, number> = {
	    Easy: 0.75,
	    Medium: 1,
	    Hard: 1.25,
	};

	//Update total score for each section
	const difficultyScore = difficultyScoreMultiplier[question.Difficulty as Difficulty];
	sectionScoresTemp[question.Section].totalScore += difficultyScore;

	const userAnswer = this.props.answers[question.QuestionID];
	if (userAnswer === null) {
	    results[question.Section][question.Module].push({QuestionID: question.QuestionID, correctness: false, answer: "", time: this.props.times[question.QuestionID]});
	}else {

	   if (question.Multichoice && typeof userAnswer === 'number') {
		if(question.Choices[userAnswer][2]) {
		    results[question.Section][question.Module].push({QuestionID: question.QuestionID, correctness: true, answer: question.Choices[userAnswer][1], time: this.props.times[question.QuestionID]});
		    sectionScoresTemp[question.Section].score += difficultyScore
		} else {
		    results[question.Section][question.Module].push({QuestionID: question.QuestionID, correctness: false, answer: question.Choices[userAnswer][1], time: this.props.times[question.QuestionID]});
		}
	    } else {
		let found = false;
		question.Choices.forEach((choice) => {
		    if(userAnswer === choice[1]) {
			results[question.Section][question.Module].push({QuestionID: question.QuestionID, correctness: true, answer: userAnswer, time: this.props.times[question.QuestionID]});
			sectionScoresTemp[question.Section].score += difficultyScore
			found = true;
		    }
		});

		if(!found) {
		    results[question.Section][question.Module].push({QuestionID: question.QuestionID, correctness: false, answer: userAnswer.toString(), time: this.props.times[question.QuestionID]});
		}
	    }
	}
    
    });

	//Calculate Score as a proportion then add by 200
	Object.keys(sectionScoresTemp).forEach(sectionKey => {
	    const section = sectionScoresTemp[+sectionKey]; // Using + to convert string to number
	    const { score, totalScore } = section;
	    const scoreProportion = (score / totalScore) * 600;

	    // Modify the rounding to round up to the nearest tenth
	    const roundedScore = Math.ceil(scoreProportion / 10) * 10; // Rounds up to the nearest tenth
	    const finalScore = roundedScore + 200;

	    // Assuming you want the finalScore also rounded up to the nearest tenth
	    const finalScoreRounded = Math.ceil(finalScore / 10) * 10; // Adjust if finalScore should be treated differently

	    sectionScoresTemp[+sectionKey].finalScore = finalScoreRounded;

	});
	if(!this.requestSent && this.props.UserID !== undefined && this.props.addToDB){
		console.log(this.props.TestID);
		console.log(this.props.UserID);
		try {
		    const response = axios.post(`${API_URL}/results`, {
			TestID: this.props.TestID,
			UserID: this.props.UserID,
			Results: results,
			Scores: sectionScoresTemp
		    })
	    		console.log(response);
	    		this.requestSent = true;
		} catch (error) {
	    		console.error(error);
		}
	}
        this.setState({correctness: results, sectionScores: sectionScoresTemp});
  }

  componentDidMount() {
      this.calculateResults();
      
      this.props.questions.sort((a, b) => {
	    // Compare by Section first
	    if (a.Section !== b.Section) {
        	return a.Section - b.Section;
	    }
	    // If Section is the same, then compare by Module
	    return a.Module - b.Module;
	});
      const tempQuestionNumber: {[questionID: string]: number} = {};
      this.props.questions.forEach((question, index) => {
	tempQuestionNumber[question.QuestionID] = index + 1;
      });
      this.setState({questionNumber: tempQuestionNumber});
  }
    handleFilterChange = (filterName: keyof FilterState, value: string) => {
    	this.setState(prevState => ({
        ...prevState,
        filters: {
            ...prevState.filters,
            [filterName]: (filterName === 'section' || filterName === 'module') ? parseInt(value) || 0 : value
        }
    }));
}

    getFilteredQuestions = (): IQuestion[] => {
        const { filters } = this.state;
        return this.props.questions.filter(question => {
            return (filters.section === 0 || question.Section === filters.section) &&
                   (filters.module === 0 || question.Module === filters.module) &&
                   (filters.difficulty === '' || question.Difficulty === filters.difficulty) &&
                   (filters.domain === '' || question.Domain === filters.domain) &&
                   (filters.skill === '' || question.Skill === filters.skill) &&
                   (filters.test === '' || question.Test === filters.test);
        });
    }
    
	getUniqueFilterOptions = (filterKey: keyof FilterState) => {
	    // Determine the corresponding key in IQuestion
	    const questionKey = this.mapFilterKeyToQuestionKey(filterKey);
	    const uniqueOptions = new Set(this.props.questions.map(question => question[questionKey]));

	    // Convert options to strings for rendering in select dropdown
	    return Array.from(uniqueOptions).map(String).sort();
	}

	mapFilterKeyToQuestionKey = (filterKey: keyof FilterState): keyof IQuestion => {
	    switch (filterKey) {
		case 'section': return 'Section';
		case 'module': return 'Module';
		case 'difficulty': return 'Difficulty';
		case 'domain': return 'Domain';
		case 'skill': return 'Skill';
		case 'test': return 'Test';
		default: return filterKey as keyof IQuestion;
	    }function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Pad the seconds with a leading zero if less than 10
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${minutes}:${paddedSeconds}`;
}

	}

    renderFilterControl = (filterKey: keyof FilterState, label: string) => {
        const options = this.getUniqueFilterOptions(filterKey);
        const { filters } = this.state;

        return (
            <div>
                <label>{label}: </label>
                <select
	            className="label-space" 
                    name={filterKey} 
                    value={filters[filterKey] as string} 
                    onChange={(e) => this.handleFilterChange(filterKey, e.target.value)}
                >
                    <option value="">All</option>
                    {options.map(option => (
    			<option key={option} value={option}>{option}</option>
			))}
                </select>
            </div>
        );
    }
    
    formatTime = (seconds: number): string => {
  	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;

  	// Pad the seconds with a leading zero if less than 10
  	const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  	return `${minutes}:${paddedSeconds}`;
    }

  
render() {
    const { correctness, questionNumber } = this.state;
    const filteredQuestions = this.getFilteredQuestions();
    const {filters} = this.state;
    return (
    <div>
    <h1>{this.props.cName} Results</h1>
    <h2>{this.props.isPractice ? "Untimed" : "Timed"}</h2>
    	<div className="results-container">
	    {Object.entries(this.state.sectionScores).map(([sectionKey, scoreData]) => (
		<div className="section-container" key={sectionKey}>
		    <h2>Section {sectionKey}</h2>
		    <div>Score: {scoreData.finalScore}</div>
		</div>
	    ))}	
	</div>
	<div className="filter-controls">
	    {this.renderFilterControl('section', 'Section')}
	    {this.renderFilterControl('module', 'Module')}
	    {this.renderFilterControl('difficulty', 'Difficulty')}
	    {this.renderFilterControl('domain', 'Domain')}
	    {this.renderFilterControl('skill', 'Skill')}
	    {this.renderFilterControl('test', 'Test')}
	</div>
        <div>
            {filteredQuestions.map((question, count) => (
                <div className={'box'} key={question.QuestionID}>
		{/*<h4>QuestionID: {question.QuestionID}</h4>*/}
		    <h4 className="number-box">{questionNumber[question.QuestionID]}</h4>
                    <h3> Section: {question.Section} Module: {question.Module}</h3>
                    <h4> Time: {this.formatTime(this.props.times[question.QuestionID])} </h4>
                    <h4> Assessment: {question.Assessment} Skill: {question.Skill}  Difficulty: {question.Difficulty}</h4>
		    {question.Image && <img src={`data:image/jpeg;base64, ${question.Image}`} alt="Question Image" />}
                    {question.Passage && <div dangerouslySetInnerHTML={{__html: question.Passage}} />}
                    {question.Multichoice ? (
                        <>
                            <div dangerouslySetInnerHTML={{__html: question.Question}} />
                            {question.Choices.map((choice, index) => {
                                const userAnswer = this.props.answers[question.QuestionID];
                                let boxClass = 'box'; // Default class
                                
                                // Apply 'correct' or 'incorrect' class based on the user's answer
                                if (userAnswer !== null && userAnswer === index) {
                                    boxClass += choice[2] ? ' correct' : ' incorrect';
                                }

                                return (
                                    <div className={boxClass} key={index}>
                                        <div dangerouslySetInnerHTML={{__html: choice[1]}} />
                                        <div className="choice">{choice[2] ? "Correct" : "Incorrect"}</div>
                                        <div dangerouslySetInnerHTML={{__html: choice[3]}} />
                                    </div>
                                );
                            })}
                        </>
                    ) : (
			<>	
			{question.Question && <div dangerouslySetInnerHTML={{__html: question.Question}} />}
			{this.props.answers[question.QuestionID] ? (<div>Student Answer: <b>{this.props.answers[question.QuestionID]}</b></div>) : (<div>Student Answer: <b>None</b> </div>)}
			{question.Choices.map((choice, index) => {
			    const userAnswer = this.props.answers[question.QuestionID];
			    let boxClass = 'box'; // Default class
			    if(userAnswer !== null && userAnswer === choice[1]) {
				boxClass += ' correct';
			    }
			    return (
				<div className={boxClass} key={index}>
				    <div dangerouslySetInnerHTML={{__html: choice[1]}} />
				    <div dangerouslySetInnerHTML={{__html: choice[3]}} />
				</div>
				);
			})}
			</>		
                    )}
                </div>
            ))}
        </div>
	</div>
    );
}
}

export default Results;
