import React, { Component } from 'react';
import '../css/BreakScreen.css'; // Make sure to create a corresponding CSS file for styling
import Timer from './tools/Timer';


interface BreakScreenProps {
  timeLeft: number;
  onTimerEnd: () => void;
}

class BreakScreen extends Component<BreakScreenProps> {
  render() {
      const { timeLeft, onTimerEnd } = this.props;

  return (
    <div className="break-screen">
      <div className="timer">
        <h1>Remaining Break Time:</h1>
	<Timer initialTime={timeLeft} onTimerEnd={onTimerEnd} />
	<button onClick={onTimerEnd}>Continue</button>
      </div>
      <div className="instructions">
        <h2>Practice Test Break</h2>
        <p>You can resume this practice test as soon as you're ready to move on. On test day, you'll wait until the clock counts down. Read below to see how breaks work on test day.</p>
        <h2>Take a Break: Do Not Close Your Device</h2>
        <p>You may leave the room, but do not disturb students who are still testing.</p>
        <p>Testing won't resume until you return.</p>
        <h3>Follow these rules during the break:</h3>
        <ol>
          <li>Do not access your phone, smartwatch, textbooks, notes, or the internet.</li>
          <li>Do not eat or drink in the test room.</li>
          <li>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
        </ol>
      </div>
    </div>
  );
}
}

export default BreakScreen;

