import React, { useRef, useEffect } from 'react';

const DesmosCalculator: React.FC = () => {
  const calculatorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Dynamically load the Desmos API script
    const script = document.createElement('script');
    script.src = 'https://www.desmos.com/api/v1.6/calculator.js?apiKey=dcb31709b452b1cf9dc26972add0fda6';
    script.async = true;
    document.body.appendChild(script);

    let calculator: any;

    script.onload = () => {
      if (calculatorRef.current) {
        // @ts-ignore: Desmos will be available globally once the script loads
        calculator = Desmos.GraphingCalculator(calculatorRef.current);
        calculator.setExpression({ id: 'graph1', latex: 'y=x^2' });
      }
    };

    // Cleanup the calculator instance on component unmount
    return () => {
      if (calculator) {
        calculator.destroy();
      }
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="calculator" ref={calculatorRef} style={{ width: '600px', height: '400px' }} />
  );
};

export default DesmosCalculator;