import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import {NavigateFunction} from 'react-router-dom';
import axios from 'axios';
import '../../css/ExamQuestion.css'; // Reuse the same CSS for basic styling
import { QuestionData, Choice, UserData } from '../types/types';
import ImageUploader from '../ImageUploader';
import HamburgerMenu from './HamburgerMenu'
import WarningMessage from './WarningMessage';
import { API_URL } from '../../config';
//TODO: Add text that displays if adding was succesful or not

type QuestionFormProps = {
    navigate: NavigateFunction;
    data: UserData | null;
}

const QuestionForm: React.FC<QuestionFormProps> = ({data, navigate}) => {
  const initialQuestionData = {
    QuestionID: '',
    Section: 0,
    Module: 0,
    Passage: '',
    Question: '',
    Assessment: 'Select Assessment',
    Test: '',
    Domain: '',
    Skill: '',
    Difficulty: '',
    Choices: [
      { Choice: '', Correct: false, Rationale: '' },
      { Choice: '', Correct: false, Rationale: '' },
      { Choice: '', Correct: false, Rationale: '' },
      { Choice: '', Correct: false, Rationale: '' },
    ],
    Correction: false,
    Approved: false,
    Multichoice: true,
    Image: null,
  };
  const [questionData, setQuestionData] = useState<QuestionData>(initialQuestionData);

  const [selectedAssessment, setSelectedAssessment] = useState('');
  const [selectedTest, setSelectedTest] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('');
  const [hasImage, setHasImage] = useState(Boolean);
  const [warningMessage, setWarningMessage] = useState('');
  const [warningKey, setWarningKey] = useState(0);

  const handleChoiceChange = (index: number, field: keyof Choice, value: string | boolean) => {
    // If the field being changed is 'Correct', we need to ensure only one choice can be correct
    if (field === 'Correct' && value === true) {
      // Set all choices 'Correct' to false, except for the one being changed
      const updatedChoices = questionData.Choices.map((choice, i) =>
        ({ ...choice, Correct: i === index })
      );
      setQuestionData({ ...questionData, Choices: updatedChoices });
    } else {
      // For all other fields, proceed as before
      const updatedChoices = questionData.Choices.map((choice, i) =>
        i === index ? { ...choice, [field]: value } : choice
      );
      setQuestionData({ ...questionData, Choices: updatedChoices });
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setWarningMessage('');
    let warningMessage = "";

 
    if(questionData.QuestionID === '')
      warningMessage = warningMessage + "Question ID field missing\n";


    if(questionData.Question === '')
      warningMessage = warningMessage + "Question field missing\n";

    if(questionData.Test === '')
      warningMessage = warningMessage + "Test field missing\n";

    if(questionData.Domain === '')
      warningMessage = warningMessage + "Domain field missing\n";

    if(questionData.Skill === '')
      warningMessage = warningMessage + "Skill field missing\n";
    
    if(questionData.Difficulty === '')
      warningMessage = warningMessage + "Difficult field missing\n";


    let correctOption = false;

    questionData.Choices.map((choice, index) => {
      if(choice.Choice === '')
        warningMessage = warningMessage + (index+1) + " Choice text field missing";
      if(choice.Rationale === '')
        warningMessage = warningMessage + (index+1) + " Choice rationale field missing";

      if(choice.Correct)
        correctOption = true; 

    })

    if(questionData.Multichoice && !correctOption)
      warningMessage = warningMessage + "Select a choice to be correct";

    if(warningMessage !== "")
    {
      setWarningMessage(warningMessage);
      setWarningKey(prevKey => prevKey + 1)
      return;
    }
    else {

      try {
        const response = await axios.post(`${API_URL}/add_question`, questionData);
        console.log(response.data);
        resetForm();
      } catch (error: any) {

	console.error('There was an error!', error);

  // Initialize a default error message
      let errorMessage = 'There was an error adding the question. Please try again later.';

  // Check if the error has a response and the response has data
      if (error.response && error.response.data && error.response.data.error) {
    // Update the error message with the one from the backend
	errorMessage = error.response.data.error;
      } else if (error.message) {
    // Fallback to error message if available and no response data is found
	errorMessage = error.message;
      }
	console.log(error.response);
	setWarningMessage(errorMessage);
	setWarningKey(prevKey => prevKey + 1);
      }
    }
  };

  const handleImageChange = () => {
    setHasImage(!hasImage);
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;

    if(name == 'Assessment'){
	setSelectedAssessment(value);
    }

    if(name == 'Test'){
      setSelectedTest(value);
    }

    if (name == 'Domain'){
      setSelectedDomain(value);
    }

    setQuestionData({
      ...questionData,
      [name]: value
    });
  };

  const getAssessmentOptions = () => {
    switch(selectedAssessment){
	case 'SAT':
	    return ['Select Test', 'Reading and Writing', 'Math'];
	case 'AP':
	    return ['Select Test', 'Microeconomics'];
	case 'Debug':
	    return ['Select Test', 'Debug'];
	default:
	    return [];
	}
    
  };

    // Function to get domain options based on the selected test
    const getDomainOptions = () => {
      switch (selectedTest) {
        case 'Reading and Writing':
          return ['Select Domain', 'Information and Ideas', 'Craft and Structure', 'Expression of Ideas', 'Standard English Conventions'];
        case 'Math':
          return ['Select Domain', 'Algebra', 'Advanced Math', 'Problem-Solving and Data Analysis', 'Geometry and Trigonometry'];
	case 'Microeconomics':
	    return ['Select Domain', 'Basic Economic Concepts', 'Supply and Demand', 'Production, Costs, and Perfect Competition', 'Imperfect Competition', 'Factor Markets', 'Market Failure and the Role of Government'];
        case 'Debug':
          return ['Select Domain', 'Debug'];
        default:
          return [];
      }
    };

    const getSkillOptions = () => {
      switch(selectedDomain){
        case 'Information and Ideas':
          return ['Select Skill', 'Command of Evidence', 'Central Ideas and Details', 'Inferences'];
        case 'Craft and Structure':
          return ['Select Skill', 'Words in Context', 'Text Structure and Purpose', 'Cross-Text Connections'];
        case 'Expression of Ideas':
          return['Select Skill', 'Rhetorical Synthesis', 'Transitions'];
        case 'Standard English Conventions':
          return['Select Skill', 'Form, Structure, and Sense', 'Boundaries'];
        case 'Algebra':
          return ['Select Skill', 'Linear equations in one variable', 'Linear functions', 'Linear equations in two variables', 'System of two linear equations in two variables', 'Linear inequalities in one or two variables']
        case 'Advanced Math':
          return['Select Skill', 'Nonlinear functions', 'Nonlinear equations in one variable and systems of equations in two variables', 'Equivalent expressions']
        case 'Problem-Solving and Data Analysis':
          return ['Select Skill', 'Ratios, rates, proportional relationships, and units', 'Percentages', 'One-variable data: Distributions and measures of center and spread', 'Two-variable data: Models and scatterplots', 'Probability and conditional probability', 'Inference from sample statistics and margin of error', 'Evaluating statistical claims: Observational studies and experiments']
        case 'Geometry and Trigonometry':
          return ['Select Skill', 'Area and volume', 'Lines, angles, and triangles', 'Right triangles and trigonometry', 'Circles']
	case 'Basic Economic Concepts':
	    return ['Select Skill', 'Scarcity', 'Resource Allocation and Economic Systems', 'Production Possibilities Curve', 'Comparative Advantage and Trade', 'Cost-Benefit Analysis', 'Marginal Analysis and Conusmer Choice'];
	case 'Supply and Demand':
	    return ['Select Skill', 'Demand', 'Supply', 'Price Elasticity of Demand', 'Price Elasticity of Supply', 'Other Elasticities', 'Market Equilibrium and Consumer and Producer Surplus', 'Market Disequilibrium and Changes in Equilibrium', 'The Effects of Government Intervention in Markets', 'International Trade and Public Policy'];
	case 'Production, Costs, and Perfect Competition':
	    return ['Select Skill', 'The Production Function', 'Short-Run Production Costs', 'Long-Run Production Costs', 'Types of Profit', 'Profit Maximization', 'Firms\' Short-Run Decisions to Produce and Long-Run Decisions to Enter or Exit a Market', 'Perfect Competition'];
	case 'Imperfect Competition':
	    return ['Select Skill', 'Introduction to Imperfectly Competitive Markets', 'Monopoly', 'Price Discrimination', 'Monopolistic Competition', 'Oligopoly and Game Theory'];
	case 'Factor Markets':
	    return ['Select Skill', 'Introduction to Factor Markets', 'Changes in Factor Demand and Factor Supply', 'Profit-Maximizing Behaior in Perfectly Competitive Factor Markets', 'Monopsonistic Markets'];
	case 'Market Failure and the Role of Government':
	    return ['Select Skill', 'Socially Efficient and Inefficient Market Outcomes', 'Externalities', 'Public and Private Goods', 'The Effects of Government Intervnetino in Different Market Structures', 'Inequality'];
        default:
          return [];
      }
    }

    // This function will handle the change for the multichoice checkbox
    const handleMultiChoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQuestionData({
          ...questionData,
          Choices : event.target.checked ? [
            { Choice: '', Correct: false, Rationale: '' },
            { Choice: '', Correct: false, Rationale: '' },
            { Choice: '', Correct: false, Rationale: '' },
            { Choice: '', Correct: false, Rationale: '' },
          ] : [],
          Multichoice: event.target.checked
        });
      };

    const addChoice = (multi: boolean) => {
      setQuestionData({
        ...questionData,
        Choices: [...questionData.Choices, { Choice: '', Correct: multi, Rationale: '' }]
      });
    };
    
    const removeChoice = () => {
      if (questionData.Choices.length > 0) {
        const updatedChoices = questionData.Choices.slice(0, -1);
        setQuestionData({
          ...questionData,
          Choices: updatedChoices
        });
      }
    };

    const resetForm = () =>{
      setQuestionData(initialQuestionData);
      setSelectedTest('');
      setSelectedDomain('');
      setHasImage(false);
    }

  return (
    <div>
	{<HamburgerMenu role={data?.role} firstName={data?.first_name} lastName={data?.last_name} />}
        {warningMessage && <WarningMessage message={warningMessage} key={warningKey}/>}
        <form onSubmit={handleSubmit} className="question-form">
    <div className="exam-content">
      {questionData.QuestionID != '' && hasImage && <ImageUploader questionID={questionData.QuestionID} />}
        <div className="question-side">
            <div>
                <textarea
                    name="Passage"
                    value={questionData.Passage ? questionData.Passage : ""}
                    onChange={(e) => handleChange(e)}
                    className="passage-input"
                    style={{ width: '100%', height: '200px' }}
                />
            </div>
            <div>
              Question ID : 
                <input
                    type="text"
                    name="QuestionID"
                    value={questionData.QuestionID}
                    onChange={(e) => handleChange(e)}
                    className="question-id-input"
                />
                {/*<input
                    type="text"
                    name="Assessment"
                    value={questionData.Assessment}
                    onChange={(e) => handleChange(e)}
                    placeholder="Assessment"
                    className="assessment-input"
  />*/}
                Has Image
                <input 
                  type="checkbox"
                  id="imageChecker"
                  value="Has Image"
                  checked={hasImage}
                  onChange={handleImageChange}
                />

		<select
		    name="Assessment"
		    value={questionData.Assessment}
		    onChange={(e) => handleChange(e)}
		    className="assessment-select"
		    >
		    <option value="">Select Assessment</option>
                    <option value="SAT">SAT</option>
                    <option value="AP">AP</option>
                    <option value ="Debug">Debug</option>
		</select>
                <select
                    name="Test"
                    value={questionData.Test}
                    onChange={(e) => handleChange(e)}
                    className="test-select"
                    >
                    
		    {getAssessmentOptions().map((option, index) => (
		    <option key={index} value={option}>{option}</option>
		    ))}
                </select>
                <select
                    name="Domain"
                    value={questionData.Domain}
                    onChange={(e) => handleChange(e)}
                    className="domain-select"
                    >
                    {getDomainOptions().map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
                </select>
                <select
                  name="Skill"
                  value={questionData.Skill}
                  onChange={(e) => handleChange(e)}
                  className="skill-input"
                >
                  {getSkillOptions().map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                  ))}
                  </select>
                <select
                name="Difficulty"
                value={questionData.Difficulty}
                onChange={(e) => handleChange(e)}
                className="difficulty-select"
                >
                    <option value="">Select Difficulty</option>
                    <option value="Easy">Easy</option>
                    <option value="Medium">Medium</option>
                    <option value="Hard">Hard</option>
                </select>
            </div>
        </div>
    
        <div className="divider"></div>
        <div className="options-side">
        <textarea
                name="Question"
                value={questionData.Question}
                onChange={(e) => handleChange(e)}
                placeholder="Enter question"
                className="question-input"
                style={{ width: '50%'}}
            />

    <label>
          Multichoice:
          <input
            type="checkbox"
            checked={questionData.Multichoice}
            onChange={handleMultiChoiceChange}
          />
        </label>
	<div>
        {!questionData.Multichoice ?  (
              <button type="button" onClick={() => addChoice(true) } className="add-choice-button">Add Choice</button>
	      ) : ( 
              <button type="button" onClick={() => addChoice(false)} className="add-choice-button">Add Choice</button>
	      )
              }
	    
              <button type="button" onClick={removeChoice} className="remove-choice-button">Remove Choice </button>
	      </div>
            {
                questionData.Choices.map((choice, index) => (
                    <div key={index} className="choices-section">
                    <textarea
                        value={choice.Choice}
                        onChange={(e) => handleChoiceChange(index, 'Choice', e.target.value)}
                        placeholder={`Choice ${String.fromCharCode(65 + index)}`}
                        className="choice-input"
                    />
                    {questionData.Multichoice && <input
                        type="radio"
                        name="CorrectChoice"
                        checked={choice.Correct}
                        onChange={(e) => handleChoiceChange(index, 'Correct', e.target.checked)}
                        className="correct-radio"
                    />}
                    <textarea
                        value={choice.Rationale}
                        onChange={(e) => handleChoiceChange(index, 'Rationale', e.target.value)}
                        placeholder="Rationale"
                        className="rationale-input"
                    />
                    </div>
                ))}
        

        <button type="submit" className="submit-button">Submit</button>
        </div>
    </div>
        </form>
        <div className="passage-viewer">
            {questionData.Passage && <h3>Passage Preview
            <div style={{fontWeight: 'normal'  }} dangerouslySetInnerHTML={{ __html: questionData.Passage }} /></h3>}
            <h3>Question Preview</h3>
            <div dangerouslySetInnerHTML={{__html: questionData.Question}} />
            <h3>Choices:</h3>
            {questionData.Choices.map((option, index) => (
              <li>
                {questionData.Multichoice ? (<span>{String.fromCharCode(65+index)}</span>) : (<span>{index+1}.</span>)}
                <div dangerouslySetInnerHTML={{__html:option.Choice}} />
                Rationale: <div dangerouslySetInnerHTML={{__html:option.Rationale}} />
              </li>
            ))}
        </div>
    </div>
    
  );
};

export default QuestionForm;
