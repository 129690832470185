import React, { useState, useEffect } from 'react';
import '../../css/SuccessMessage.css';

interface SuccessMessageProps {
  message: string;
  key: any;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ message, key }) => {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setShowSuccess(true);
    const timer = setTimeout(() => {
      setShowSuccess(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [key]);

  if (!showSuccess) {
    return null;
  }

  return (
    <div className="success-message">
      {message}
    </div>
  );
};

export default SuccessMessage;
