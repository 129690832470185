import React, { Component } from 'react';
import '../css/ExamStart.css'; // Import the CSS file for styles

interface ExamStartProps {
  toggleStart: () => void;
}

class ExamStart extends Component<ExamStartProps> {
  render() {
    const {toggleStart} = this.props;
    return (
      <div className="Exam-Start-Body">
        <div className="content-wrapper">
          <h1>Exam Instructions</h1>
          <div className="instruction-box">
            <h2>Timing</h2>
            <p>Full-length practice tests are timed like real exams. If you exit from the exam or refresh, your progress will be lost.</p>
            
            <h2>Scores</h2>
            <p>When you finish the practice test, you will be redirected to a page with your scores. You can email those scores to yourself, and later import them back onto the website for review.</p>
            
            <h2>No Lockdown Mode</h2>
            <p>As these are just for practice tests, there will be no lockdown mode, and as such, there will be nothing preventing you from accessing other programs or apps.</p>
	  <div className="button-container">
            <button onClick={toggleStart}>Start Exam</button>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExamStart;

