import React, { Component } from 'react';
import axios from 'axios';
import { Classroom, UserData, Tests } from './types/types'; // Ensure the types are correctly imported
import { fetchTestQuestions } from './api/fetchQuestions';
import StudentProfile from './StudentProfile'
import SuccessMessage from './tools/SuccessMessage';
import WarningMessage from './tools/WarningMessage';
import '../css/TeacherProfile.css'
import { API_URL } from '../config';

type TeacherProfileProps = {
    teacherID?: number;
};

type TeacherProfileState = {
    editCreateClass: boolean,
    currentStudent: UserData | null;
    classrooms: Classroom[],
    currentStudents: UserData[],
    nonClassStudents: UserData[], // Add this to store students not in the class
    showNonClassStudents: boolean, // Add this to control the display of non-enrolled students
    error: string | null,
    currentViewedClassID: string | null,
    assignExam: boolean,
    tests: Tests[],
    currentSelectedTestID: string | null,
    currentSelectedTestName: string | null,
    successMessage: string,
    successKey: number,
    warningMessage: string,
    warningKey: number,
    isPractice: boolean,
    filteredTests: Tests[],
    selectedTestType: string,
};

class TeacherProfile extends Component<TeacherProfileProps, TeacherProfileState> {
    constructor(props: TeacherProfileProps) {
        super(props);
        this.state = {
	    editCreateClass: false,
            classrooms: [],
	    currentStudent: null,
    currentStudents: [],
    nonClassStudents: [],
    showNonClassStudents: false, // Initially false, so the list is not shown
    error: null,
    currentViewedClassID: null,
    assignExam: false,
    tests: [],
    currentSelectedTestID: null,
    currentSelectedTestName: null,
    successMessage: "",
    successKey: 0,
    warningMessage: "",
    warningKey: 0,
    isPractice: false,
    filteredTests: [],
    selectedTestType: "",
        };
    }

    componentDidMount() {
        const url = `${API_URL}/get_classrooms?teacherID=${this.props.teacherID}`;

        axios.get(url)
            .then(response => {
                this.setState({ classrooms: response.data });
            })
            .catch(error => {
                console.error('There was an error fetching the classrooms:', error);
                this.setState({ error: error.message });
            });
    }

    viewClass = (classID: string) => {
        // Toggle the display off if the same class is clicked again
        if (this.state.currentViewedClassID === classID) {
            this.setState({ currentStudents: [], nonClassStudents: [], showNonClassStudents: false, currentViewedClassID: null });
            return;
        } else {
        	this.closeAddStudent();
        	this.closeAssignExam();
        }

        // Otherwise, fetch the new class's students
        const url = `${API_URL}/get_class_students?classID=${classID}`;

        axios.get(url)
            .then(response => {
                this.setState({ currentStudents: response.data, currentViewedClassID: classID });
            })
            .catch(error => {
                console.error('There was an error fetching the students from the class:', error);
                this.setState({ error: error.message, currentViewedClassID: null });
            });
    }
    
    closeClassroom = () => {
	this.setState({ currentStudents: [], nonClassStudents: [], showNonClassStudents: false, currentViewedClassID: null });
	}
    
    addStudent = () => {
    const { currentViewedClassID } = this.state;
    this.closeAssignExam();
    
    if (currentViewedClassID === null) return;

    const url = `${API_URL}/get_non_class_students?classID=${currentViewedClassID}`;

    axios.get(url)
        .then(response => {
            this.setState({ nonClassStudents: response.data, showNonClassStudents: true });
        })
        .catch(error => {
            console.error('There was an error fetching non-class students:', error);
            this.setState({ error: error.message });
        });
    };
    
    closeAddStudent = () => {
    	this.setState({nonClassStudents: [], showNonClassStudents: false});
    };

    insertStudentToClass = (studentID: number) => {
	console.log(`Inserting student with ${studentID} to class with ID ${this.state.currentViewedClassID}`);
	
	
	    const url = `${API_URL}/insert_student_to_class`;
	    const postData = {
        	ClassID: this.state.currentViewedClassID,
        	StudentID: studentID,
    		};

	    axios.post(url, postData)
        	.then(response => {
        	    console.log(response.data.message);
        	    // Find the added student in nonClassStudents and remove them
        	    const updatedNonClassStudents = this.state.nonClassStudents.filter(student => student.userID !== studentID);

        	    // Optionally, if you have access to the full student data here, add it to currentStudents
        	    // For simplicity, let's assume you only append the studentID for now
        	    // Ideally, you'd want to fetch the full student info or have it passed in the response
        	    const addedStudent = this.state.nonClassStudents.find(student => student.userID === studentID);
        	    const updatedCurrentStudents = addedStudent ? [...this.state.currentStudents, addedStudent] : [...this.state.currentStudents];

        	    this.setState({
        	        nonClassStudents: updatedNonClassStudents,
        	        currentStudents: updatedCurrentStudents,
	            });
        	})
        	.catch(error => {
        	    console.error('There was an error inserting the student into the class:', error);
        	    // Handle the error, e.g., by showing an error message to the user
        	});
    }
    
    removeStudentFromClass = (studentID: number) => {
    	console.log(`Removing student with ID ${studentID} from class with ID ${this.state.currentViewedClassID}`);
    	if (!this.state.currentViewedClassID) return;

	    const url = `${API_URL}/remove_student_from_class`;
	    const postData = {
        	ClassID: this.state.currentViewedClassID,
        	StudentID: studentID,
    	};	

	    axios.post(url, postData)
        	.then(response => {
        	    console.log(response.data.message);
        	    // Update the currentStudents list to exclude the removed student
        	    const updatedCurrentStudents = this.state.currentStudents.filter(student => student.userID !== studentID);
        	    // Optionally, refresh nonClassStudents list or perform other updates
        	    this.setState({ currentStudents: updatedCurrentStudents });
        	    
        	    if(this.state.showNonClassStudents){
        	    	const removedStudent = this.state.currentStudents.find(student => student.userID === studentID);
        	   	const updatedNonStudent = removedStudent ? [...this.state.nonClassStudents, removedStudent] : [...this.state.nonClassStudents]; 
        	    	this.setState({nonClassStudents: updatedNonStudent});
        	    }
        	    
        	})
        	.catch(error => {
        	    console.error('There was an error removing the student from the class:', error);
        	    // Handle the error, e.g., by showing an error message to the user
        	});
	}
    assignExam = () => {
    	if(this.state.assignExam){
    		this.closeAssignExam();
    		return;
    	}
    	
    	this.closeAddStudent();
    	this.setState({assignExam:true});
    	fetchTestQuestions(`${API_URL}/get_tests`)
      	 .then((data) => { this.setState({ tests: data });
	
	if(data.length > 0) {
	    this.setState({currentSelectedTestID: data[0].TestID, currentSelectedTestName: data[0].TestName});
	}
      })
      .catch((error) => console.error(error));
    	
    }
    handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
  	const testID = selectedOption.value;
  	const testName = selectedOption.getAttribute('data-testname');
  	
  	this.setState({
    		currentSelectedTestID: testID,
    		currentSelectedTestName: testName, // You'll need to add this to your state definition
  	});
  	
  	console.log(testName);
    }
    
    SendExam = () => {
    	const cStudents = this.state.currentStudents.map(student => ({"id": student.userID, "email": student.email}));
    	const currentClassID = this.state.currentViewedClassID;
    	const currentTestID = this.state.currentSelectedTestID;
    	const currentTestName = this.state.currentSelectedTestName;
    	
    	if (!currentClassID || !currentTestID || cStudents.length === 0) {
        	console.error('Missing data for sending exam');
        	this.setState(prevState => ({
        	    warningMessage: 'Missing data for sending exam',
	            warningKey: prevState.warningKey + 1
        		}));
        	return; // Handle this case as needed
    	}

    	const url = `${API_URL}/assign_exam_to_students`;
	const postData = {
        	ClassID: currentClassID,
        	TestID: currentTestID,
        	TestName: currentTestName,
        	students: cStudents,
    	};

    	axios.post(url, postData)
        	.then(response => {
            	console.log(response.data.message);
            	// Handle success, maybe refresh the list of assigned exams or update the UI\
            	this.setState(prevState => ({
        	    successMessage: response.data.message,
	            successKey: prevState.successKey + 1
        		}));
        	})
        	.catch(error => {
            	console.error('There was an error assigning the exam to students:', error);
            	this.setState(prevState => ({
        	    warningMessage: 'There was an error assigning the exam to students:', error,
	            warningKey: prevState.warningKey + 1
        		}));

        	});
    	
    	}
    	
    assignStudentTest = (studentID: number, studentEmail: String) => {
    
        const cStudents = [{"id": studentID, "email": studentEmail}];
    	const currentClassID = this.state.currentViewedClassID;
    	const currentTestID = this.state.currentSelectedTestID;
    	const currentTestName = this.state.currentSelectedTestName;
    	
    	if (!currentClassID || !currentTestID || cStudents.length === 0) {
        	console.error('Missing data for sending exam');
        	return; // Handle this case as needed
    	}

    	const url = `${API_URL}/assign_exam_to_students`;
	const postData = {
        	ClassID: currentClassID,
        	TestID: currentTestID,
        	TestName: currentTestName,
        	students: cStudents,
		isPractice: this.state.isPractice,
    	};

    	axios.post(url, postData)
        	.then(response => {
            	console.log(response.data.message);
            	this.setState(prevState => ({
        	    successMessage: response.data.message,
	            successKey: prevState.successKey + 1
        		}));
        	})
        	.catch(error => {
            	console.error('There was an error assigning the exam to students:', error);
            	this.setState(prevState => ({
        	    warningMessage: 'There was an error assigning the exam to students:', error,
	            warningKey: prevState.warningKey + 1
        		}));
        	});
    	
    	}

    
    closeAssignExam = () => {
    	this.setState({assignExam: false, tests: [], currentSelectedTestID: null, isPractice: false});
    }

    viewStudent = (cStudent: UserData) => {

	this.setState({currentStudent: cStudent});

    }

    createClass = () => {
	if(this.state.editCreateClass){
	    this.setState({editCreateClass: false});
	    return;
	}
	this.setState({editCreateClass: true});
	this.closeAddStudent();
	this.closeAssignExam();
    }

    handleCreateClass = (e: React.FormEvent<HTMLFormElement>) => {
	    e.preventDefault();
	    const classNameInput = e.currentTarget.elements.namedItem('className') as HTMLInputElement;
	    const className = classNameInput.value;
	    if (!className || !this.props.teacherID) {
		this.setState({
		    warningMessage: "Class name or teacher ID is missing!",
		    warningKey: this.state.warningKey + 1,
		});
		return;
	    }

	    const url = `${API_URL}/create_classroom`;
	    axios.post(url, {
		ClassName: className,
		TeacherID: this.props.teacherID,
	    })
	    .then((response) => {
		const newClassroom = response.data;
        
		// Append the new classroom to the existing list of classrooms
		const updatedClassrooms = [...this.state.classrooms, newClassroom];
		
		this.setState({
		    classrooms: updatedClassrooms,
		    successMessage: "Classroom created successfully!",
		    successKey: this.state.successKey + 1,
		    editCreateClass: false, // Close the create class form upon successful creation
		});
	    })
	    .catch((error) => {
		this.setState({
		    warningMessage: "Failed to create classroom. Please try again.",
		    warningKey: this.state.warningKey + 1,
        	});
    	});
	}

      handleTestTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = event.target.value;
    const filteredTests = selectedType ? this.state.tests.filter(test => test.TestType === selectedType) : [];
    this.setState({ selectedTestType: selectedType, filteredTests });
  };
    backToAllStudents = () => {
	this.setState({currentStudent: null});
	};

    handleStudentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
	    const studentID = event.target.value;
	    const selectedUserData = this.state.currentStudents.find(student => student.userID === Number(studentID));
	    if (selectedUserData)
	    {
		    this.setState({currentStudent: selectedUserData});
	    }
	};
    render() {
        const { editCreateClass, successMessage, successKey, warningMessage, warningKey, classrooms, currentStudents, error, currentViewedClassID, nonClassStudents, showNonClassStudents, assignExam, tests } = this.state;
	// Derive unique test types for the dropdown
    const testTypes = Array.from(new Set(this.state.tests.map(test => test.TestType)));
	if (this.state.currentStudent){
	    console.log(this.state.currentStudent);
	    return(
	    <div>
	     <div className='backSection'>
		 <button className="backButton" onClick={() => this.backToAllStudents()}>Back to Class</button>
		 <select style={{marginLeft: '20px'}} value={this.state.currentStudent.userID} onChange={this.handleStudentChange}>
		{currentStudents.map((student) => (
		    <option key={student.userID} value={student.userID}>
			{student.first_name} {student.last_name}
			</option>
			))}
			</select>
	    </div>
	    <StudentProfile studentData={this.state.currentStudent} navigate={null} />
	    </div>
	    )
	}
        return (
            <div className="teacher-profile-container">
            	{successMessage && <SuccessMessage message={successMessage} key={successKey}/>}
            	{warningMessage && <WarningMessage message={warningMessage} key={warningKey}/>}
                <h1 className="teacher-profile-header">Classrooms</h1>
                {error && <p style={{ color: 'red' }}>Error: {error}</p>}
                <table className="teacher-profile-table">
                    <thead>
                        <tr>
                            <th>Class Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {classrooms.map((classroom) => (
                            <tr key={classroom.ClassID}>
                                <td>{classroom.ClassName}</td>
                                <td>
                                    <button onClick={() => this.viewClass(classroom.ClassID)}>View Class</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
		<button className="teacher-button" onClick={() => this.createClass()}>Create Class</button>
		{editCreateClass && (
		    <div>
		    <form onSubmit={this.handleCreateClass}>
			<input
			    type="text"
			    name="className"
			    placeholder="Class Name"
			    required
			    />
			<button type="submit" className="teacher-button">Submit</button>
		    </form>
		    </div>
		)}
                {currentViewedClassID && (
                    <div>
			<h2>Students in Class</h2>
			{currentStudents.length > 0 && 
                        <table className="teacher-profile-table">
                            <thead>
                                <tr>
                                    <th>Student Name</th>
                                    <th>Username</th>
				    <th>Email</th>
				    <th>Command</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentStudents.map((student, index) => (
                                    <tr key={index}>
                                        <td>{student.first_name} {student.last_name}</td>
                                        <td>{student.username}</td>
					<td>{student.email}</td>
					<td><button onClick={() => this.removeStudentFromClass(student.userID)}>Remove Student</button>
					{assignExam && <button onClick={() => this.assignStudentTest(student.userID, student.email)}>Assign Test</button>}
					<button onClick={() => this.viewStudent(student)}>View Student</button>
					</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
			<button className="teacher-button" onClick={() => this.assignExam()}>Assign Test</button>
			<button className="teacher-button" onClick={() => this.addStudent()}>Add Student</button>
			<button className="teacher-button" onClick={() => this.closeClassroom()}>Close Class</button>
                    </div>
                )}
                
                {showNonClassStudents && (
                <div>
                    <h2>Students Not in Class</h2>
                    <table className="teacher-profile-table">
                        <thead>
                            <tr>
                                <th>Student Name</th>
                                <th>Username</th>
                                <th>Email</th>
				<th>Command</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nonClassStudents.map((student, index) => (
                                <tr key={index}>
                                    <td>{student.first_name} {student.last_name}</td>
                                    <td>{student.username}</td>
                                    <td>{student.email}</td>
                                    <td><button onClick={() => this.insertStudentToClass(student.userID)}>Add Student</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="teacher-button" onClick={() => this.closeAddStudent()}>Close Add Students</button>
                </div>
            )}
            {assignExam &&  (
		<div>
            <div className="section">
              <label htmlFor="test-type-type">Select Test Type: </label>
              <select id="test-type-type" onChange={this.handleTestTypeChange}>
                <option value="">Please select</option>
                {testTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>

              {this.state.selectedTestType && (
                <>
                  <label htmlFor="test-type">Select Test: </label>
                  <select id="test-type" onChange={this.handleDropdownChange}>
                    <option value="">Please select</option>
                    {this.state.filteredTests.map(test => (
                      <option key={test.TestID} value={test.TestID}>
                        {test.TestName}
                      </option>
                    ))}
                  </select>
                </>
              )}

              <input
                type="checkbox"
                id="practiceCheckbox"
                checked={this.state.isPractice}
                onChange={() => this.setState({ isPractice: !this.state.isPractice })}
              />
              <label htmlFor="practiceCheckbox">Practice</label>
            </div>
            <button className="teacher-button" onClick={this.SendExam}>
              Assign All
            </button>
            <button className="teacher-button" onClick={this.closeAssignExam}>
              Close Assign Exam
            </button>
          </div>
        )}
      </div>
    );
    }
}

export default TeacherProfile;

