import React, {Component} from 'react';
import { IQuestion } from './types/types'; // Adjust the path as necessary
import '../css/DisplayQuestions.css'; // Make sure the path to your CSS file is correct

type DisplayQuestionsProps = {
  header : string;
  isReview: boolean;
  isVisible: boolean;
  currentQuestions: IQuestion[];
  answers: { [key: string]: number | string | null };
  goToQuestion: (index: number) => void;
  toggleQuestionDisplay: () => void;
  toReviewPage: () => void; // Add this method in your Exam component and pass it down as props
};

class DisplayQuestions extends Component<DisplayQuestionsProps> {
  render() {
    const { header, currentQuestions, answers, goToQuestion, toggleQuestionDisplay, isVisible, isReview, toReviewPage } = this.props;
    
    const modalClass = this.props.isReview ? 'review' : 'question-display-overlay';
    const contentClass = this.props.isReview ? 'question-display-content-review' : 'question-display-content';
    return (
<div className={`${modalClass} ${isVisible ? 'open' : ''}`}>
        <div className={`${contentClass}`}>
        	<div><h2> {header} Questions </h2></div>
		<div>
	          {currentQuestions.map((question, index) => {
        	  	const isAnswered = answers[question.QuestionID] != null;
        	  	const buttonClass = isAnswered ? 'question-button answered' : 'question-button';
        	  	return (
		            <button 
		            	key={question.QuestionID}
		            	className={buttonClass}
		            	onClick={() => goToQuestion(index)}>
			              {index + 1}
		            </button>
		        );
        	  })}
        	</div>
          <div><button onClick={toReviewPage}>Go to Review Page</button></div>
        </div>
      </div>
    );
  }
}

export default DisplayQuestions;
