import React, { useState } from 'react';
import axios from 'axios';
import { IQuestion, IChoice, QuestionData, IQuestions } from './types/types';
import { fetchQuestions } from './api/fetchQuestions';
import '../css/ExamQuestion.css'; // Reuse the same CSS for basic styling
import ImageUploader from './ImageUploader';
import { API_URL } from '../config';


interface QuestionEditorProps {
  question: QuestionData; // Use the correct type
  onSave: (question: QuestionData) => void; // Ensure onSave uses the correct type
}

const QuestionEditor: React.FC<QuestionEditorProps> = ({ question, onSave }) => {
  const [editQuestion, setEditQuestion] = useState<QuestionData>({ ...question });
  const [editing, setEditing] = useState<boolean>(false);
  const [selectedAssessment, setSelectedAssessment] = useState<string>(question.Assessment || '');
  const [selectedTest, setSelectedTest] = useState<string>(question.Test || '');
  const [selectedDomain, setSelectedDomain] = useState<string>(question.Domain || '');
  const [selectedSkill, setSelectedSkill] = useState<string>(question.Skill || '');
  const [selectedDifficulty, setSelectedDifficulty] = useState<string>(question.Difficulty || '');


const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, field: string, choiceIdx?: number) => {
  const target = event.target as HTMLInputElement; // Type assertion here

  if (field === "Test") {
    setSelectedTest(target.value);
    setEditQuestion({ ...editQuestion, Test: target.value });
  } else if (field === "Assessment") {
    setSelectedAssessment(target.value);
    setEditQuestion({ ...editQuestion, Assessment: target.value });
  }else if (field === "Domain") {
    setSelectedDomain(target.value);
    setEditQuestion({ ...editQuestion, Domain: target.value });
  } else if (field === "Skill") {
    setSelectedSkill(target.value);
    setEditQuestion({ ...editQuestion, Skill: target.value });
  } else if (field === "Difficulty") {
    setSelectedDifficulty(target.value);
    setEditQuestion({ ...editQuestion, Difficulty: target.value });
  } else if (choiceIdx !== undefined) {
    // Update the 'Correct' field for the selected choice and set others to false
      const updatedChoices = editQuestion.Choices.map((choice) => {
        if (field == "Correct"){
        return {
          ...choice,
          Correct: choice.ChoiceID === choiceIdx ? target.checked : false
        };
      }
        if (choice.ChoiceID === choiceIdx && (field === 'Choice' || field === 'Rationale')) {
          return {
            ...choice,
            [field]: target.value
          };
        }
        return choice;
      });
      setEditQuestion({ ...editQuestion, Choices: updatedChoices });

    
  } else if (target.type === 'checkbox') {
    setEditQuestion({ ...editQuestion, [field]: target.checked });
  } else {
    setEditQuestion({ ...editQuestion, [field]: target.value });
  }
};

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave(editQuestion);
    setEditing(false);
  };

  const getAssessmentOptions = () => {
    switch(selectedAssessment){
	case 'SAT':
	    return ['Select Test', 'Reading and Writing', 'Math'];
	case 'AP':
	    return ['Select Test', 'Microeconomics'];
	case 'Debug':
	    return ['Select Test', 'Debug'];
	default:
	    return [];
	}
    
  };

    // Function to get domain options based on the selected test
    const getDomainOptions = () => {
      switch (selectedTest) {
        case 'Reading and Writing':
          return ['Select Domain', 'Information and Ideas', 'Craft and Structure', 'Expression of Ideas', 'Standard English Conventions'];
        case 'Math':
          return ['Select Domain', 'Algebra', 'Advanced Math', 'Problem-Solving and Data Analysis', 'Geometry and Trigonometry'];
	case 'Microeconomics':
	    return ['Select Domain', 'Basic Economic Concepts', 'Supply and Demand', 'Production, Costs, and Perfect Competition', 'Imperfect Competition', 'Factor Markets', 'Market Failure and the Role of Government'];
        case 'Debug':
          return ['Select Domain', 'Debug'];
        default:
          return [];
      }
    };

    const getSkillOptions = () => {
      switch(selectedDomain){
        case 'Information and Ideas':
          return ['Select Skill', 'Command of Evidence', 'Central Ideas and Details', 'Inferences'];
        case 'Craft and Structure':
          return ['Select Skill', 'Words in Context', 'Text Structure and Purpose', 'Cross-Text Connections'];
        case 'Expression of Ideas':
          return['Select Skill', 'Rhetorical Synthesis', 'Transitions'];
        case 'Standard English Conventions':
          return['Select Skill', 'Form, Structure, and Sense', 'Boundaries'];
        case 'Algebra':
          return ['Select Skill', 'Linear equations in one variable', 'Linear functions', 'Linear equations in two variables', 'System of two linear equations in two variables', 'Linear inequalities in one or two variables']
        case 'Advanced Math':
          return['Select Skill', 'Nonlinear functions', 'Nonlinear equations in one variable and systems of equations in two variables', 'Equivalent expressions']
        case 'Problem-Solving and Data Analysis':
          return ['Select Skill', 'Ratios, rates, proportional relationships, and units', 'Percentages', 'One-variable data: Distributions and measures of center and spread', 'Two-variable data: Models and scatterplots', 'Probability and conditional probability', 'Inference from sample statistics and margin of error', 'Evaluating statistical claims: Observational studies and experiments']
        case 'Geometry and Trigonometry':
          return ['Select Skill', 'Area and volume', 'Lines, angles, and triangles', 'Right triangles and trigonometry', 'Circles']
	case 'Basic Economic Concepts':
	    return ['Select Skill', 'Scarcity', 'Resource Allocation and Economic Systems', 'Production Possibilities Curve', 'Comparative Advantage and Trade', 'Cost-Benefit Analysis', 'Marginal Analysis and Conusmer Choice'];
	case 'Supply and Demand':
	    return ['Select Skill', 'Demand', 'Supply', 'Price Elasticity of Demand', 'Price Elasticity of Supply', 'Other Elasticities', 'Market Equilibrium and Consumer and Producer Surplus', 'Market Disequilibrium and Changes in Equilibrium', 'The Effects of Government Intervention in Markets', 'International Trade and Public Policy'];
	case 'Production, Costs, and Perfect Competition':
	    return ['Select Skill', 'The Production Function', 'Short-Run Production Costs', 'Long-Run Production Costs', 'Types of Profit', 'Profit Maximization', 'Firms\' Short-Run Decisions to Produce and Long-Run Decisions to Enter or Exit a Market', 'Perfect Competition'];
	case 'Imperfect Competition':
	    return ['Select Skill', 'Introduction to Imperfectly Competitive Markets', 'Monopoly', 'Price Discrimination', 'Monopolistic Competition', 'Oligopoly and Game Theory'];
	case 'Factor Markets':
	    return ['Select Skill', 'Introduction to Factor Markets', 'Changes in Factor Demand and Factor Supply', 'Profit-Maximizing Behaior in Perfectly Competitive Factor Markets', 'Monopsonistic Markets'];
	case 'Market Failure and the Role of Government':
	    return ['Select Skill', 'Socially Efficient and Inefficient Market Outcomes', 'Externalities', 'Public and Private Goods', 'The Effects of Government Intervnetino in Different Market Structures', 'Inequality'];
        default:
          return [];
      }
    }

  return (
    <div>
      <h2>Question ID: {question.QuestionID}</h2>
      {editing ? (
        <form onSubmit={handleSubmit} className="question-form">
          <ImageUploader 
            questionID = {question.QuestionID}/>
           <div className="exam-content">
            <div>
          <textarea
            value={editQuestion.Passage ? editQuestion.Passage : ""}
            onChange={(e) => handleInputChange(e, 'Passage')}
          />
          <textarea
            value={editQuestion.Question}
            onChange={(e) => handleInputChange(e, 'Question')}
          />
          </div>
            <label>
              Approved:
              <input
                type="checkbox"
                checked={editQuestion.Approved}
                onChange={(e) => handleInputChange(e, 'Approved')}
              />
            </label>
            <label>
              Multichoice:
              <input
                type="checkbox"
                checked={editQuestion.Multichoice}
                onChange={(e) => handleInputChange(e, 'Multichoice')}
              />
            </label>
            <div>
	    <select
		    name="Assessment"
		    value={selectedAssessment}
		    onChange={(e) => handleInputChange(e, "Assessment")}
		    className="assessment-select"
		    >
		    <option value="">Select Assessment</option>
		    <option value="SAT">SAT</option>
		    <option value="AP">AP</option>
	    </select>

            <select
                    name="Test"
                    value={selectedTest}
                    onChange={(e) => handleInputChange(e, "Test")}
                    className="test-select"
                    >
                    {getAssessmentOptions().map((option, index) => (
		    <option key={index} value={option}>{option}</option>
		    ))}
                </select>
                <select
                    name="Domain"
                    value={selectedDomain}
                    onChange={(e) => handleInputChange(e, "Domain")}
                    className="domain-select"
                    >
                    {getDomainOptions().map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
                </select>
                <select
                  name="Skill"
                  value={selectedSkill}
                  onChange={(e) => handleInputChange(e, "Skill")}
                  className="skill-input"
                >
                  {getSkillOptions().map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                  ))}
                  </select>
                <select
                name="Difficulty"
                value={selectedDifficulty}
                onChange={(e) => handleInputChange(e, "Difficulty")}
                className="difficulty-select"
                >
                    <option value="">Select Difficulty</option>
                    <option value="Easy">Easy</option>
                    <option value="Medium">Medium</option>
                    <option value="Hard">Hard</option>
                </select>
            </div>
            
          <div>
            Choices:
            <ul>
              {editQuestion.Choices.map((choice, index) => (
                <li key={index}>
                  <textarea
                    value={choice.Choice}
                    onChange={(e) => handleInputChange(e, 'Choice', choice.ChoiceID)}
                  />
                  <input
                    type="checkbox"
                    checked={choice.Correct}
                    onChange={(e) => handleInputChange(e, 'Correct', choice.ChoiceID)}
                  />
                  <textarea
                    value={choice.Rationale}
                    onChange={(e) => handleInputChange(e, 'Rationale', choice.ChoiceID)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <button onClick={() => setEditing(false)}>Edit</button>
          <button type="submit">Save</button>
        </div> 
        <div className="passage-viewer">
            {editQuestion.Passage && <h3>Passage Preview
            <div dangerouslySetInnerHTML={{ __html: editQuestion.Passage }} /></h3>}
        </div>
        </form>
        
      ) : (
        <div>
          {question.Image && (
            <img src={`data:image/jpeg;base64,${question.Image}`} alt="Uploaded" />
          )}
	  {question.Difficulty && <p>Difficulty: {question.Difficulty}</p>}
	  {question.Domain && <p>Domain: {question.Domain}</p>}
	  {question.Skill && <p>Skill: {question.Skill}</p>}
          {question.Passage && <p>Passage:
          <div dangerouslySetInnerHTML={{ __html: question.Passage }} />
          </p>}
          <p>Question: 
            <div dangerouslySetInnerHTML={{__html: question.Question}} /></p>
          <p>Approved: {question.Approved ? 'Yes' : 'No'}</p>
          <p>Multichoice: {question.Multichoice ? 'Yes' : 'No'}</p>
          <div>
            Choices:
            <ul>
              {question.Choices.map((choice, index) => (
                <li key={index}>
                  <p><div dangerouslySetInnerHTML={{ __html:choice.Choice}} /></p>
		  <p><div dangerouslySetInnerHTML={{ __html: choice.Rationale}}/></p>
                </li>
              ))}
            </ul>
          </div>
          <button onClick={() => setEditing(true)}>Edit</button>
        </div>
      )}
    </div>
  );
};

interface IState {
  questions: IQuestions | null; // Use IQuestions type which is an array of IQuestion
}

class QuestionsFetcher extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      questions: null
    };
  }

//TODO: Make server a variable
  fetchDebugQuestions() {
    fetchQuestions(`${API_URL}/get_questions_all`, false)
      .then((questions) => this.setState({ questions }))
      .catch((error) => console.error('Error fetching questions:', error));
  }

  componentDidMount() {
    this.fetchDebugQuestions();
  }


  handleSave = (updatedQuestion: QuestionData) => {
    axios
    .post(`${API_URL}/update_question`, updatedQuestion)
    .then((response) => {
      this.fetchDebugQuestions();
    })
    .catch((error) => console.error('Error updating question:', error));

  };
  
  renderQuestions = () => {
    const { questions } = this.state;
    if (!questions) {
      return <div>Loading questions...</div>;
    }
  
    return questions.map((question) => {
      // Convert IQuestion to QuestionData for editing
      const questionData: QuestionData = {
        ...question,
        Choices: question.Choices.map((choice) => {
          // Here you would convert your tuple to the Choice object
          const [ChoiceID, Choice, Correct, Rationale] = choice;
          return { ChoiceID, Choice, Correct, Rationale };
        })
      };
      return (
        <QuestionEditor
          key={question.QuestionID}
          question={questionData}
          onSave={this.handleSave}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <h1>Questions</h1>
        {this.renderQuestions()}
      </div>
    );
  }
}

export default QuestionsFetcher;
